export enum CONTEXT_PROVIDER_TYPES {
	DASHBOARD_LAYOUT = 'DASHBOARD_LAYOUT',
	PACK_FILES_LAYOUT = 'PACK_FILES_LAYOUT',
	PACKS_LIST_LAYOUT = 'PACKS_LIST_LAYOUT',
	CUSTOMIZER_PANEL_SCROLL = 'CUSTOMIZER_PANEL_SCROLL',
	CUSTOMIZER_PANEL_SCROLL_TOP = 'CUSTOMIZER_PANEL_SCROLL_TOP',
	CUSTOMIZER_EDIT_PANEL_SCROLL = 'CUSTOMIZER_EDIT_PANEL_SCROLL',
	CUSTOMIZER_EDIT_PANEL_SCROLL_TOP = 'CUSTOMIZER_EDIT_PANEL_SCROLL_TOP',
	CUSTOM_PRESET_LIST_CHANGE = 'CUSTOM_PRESET_LIST_CHANGE',
	SERVER_DOWNLOAD_POPUP = 'SERVER_DOWNLOAD_POPUP',
	DOWNLOAD_POPUP = 'DOWNLOAD_POPUP',
	CUSTOMIZER_AI_COMMUNITY_ITEM_DETAl_SCROLL = 'CUSTOMIZER_AI_COMMUNITY_ITEM_DETAl_SCROLL',
	HIGHLIGHT_AI_SIDEBAR_TAB = 'HIGHLIGHT_AI_SIDEBAR_TAB',
	CUSTOMIZER_UPLOADS_LIST_SHOULD_REFRESH = 'CUSTOMIZER_UPLOADS_LIST_SHOULD_REFRESH',
	DESIGN_TEMPLATES_TAB = 'DESIGN_TEMPLATES_TAB',
	DESIGN_ENGINE_LOADED = 'DESIGN_ENGINE_LOADED',
	UPDATE_MOCKUP_IMAGE = 'UPDATE_MOCKUP_IMAGE',
	RIGHT_PANEL_DESIGN_TAB_SCROLL = 'RIGHT_PANEL_DESIGN_TAB_SCROLL',
	FIGMA_PLUGIN_LAYOUT_SCROLL = 'FIGMA_PLUGIN_LAYOUT_SCROLL',
}

export type InjectionCallback = {
	callback: (...args) => any;
	callbackName: string;
};

export type ProviderInjections = {
	[providerType: string]: InjectionCallback[];
};

export interface IContextProvider {
	emitProviderInjections: (providerType: CONTEXT_PROVIDER_TYPES, ...args) => void;
	resetProviderInjections: (providerType: CONTEXT_PROVIDER_TYPES) => void;
	registerProviderInjection: (providerType: CONTEXT_PROVIDER_TYPES, callback: InjectionCallback) => void;
	deregisterProviderInjection: (providerType: CONTEXT_PROVIDER_TYPES, callbackName: string) => void;
}
