import logger from '@pixcap/ui-core/helpers/logger';
import { WEBSOCKET_OPERATIONS } from '../WebSocketManager';
import { MockPayload } from './MockRSocket';
import { delay, serialize } from './MockUtils';

/**
 * DEFINE YOUR MOCKS FOR WEBSOCKET REQUESTS HERE
 */

export function getMockedRequestResponse(operation: WEBSOCKET_OPERATIONS) {
	switch (operation) {
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_CLONED_PROJECT:
			return async (payload: MockPayload) => {
				const { srcProjectId, newProjectId } = payload.data;

				await delay(2000);
				return {
					data: serialize({ message: { newProjectId }, status: 200 }),
				};
			};
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_STATE_JSON_READY:
			return async (payload: MockPayload) => {
				logger.log('SUBSCRIBE_STATE_JSON_READY payload', payload);
				await delay(1500);
				const { projectId } = payload.data;
				//@ts-ignore
				if (window.WEBSOCKET_CONNECTION == 'ERROR') throw new Error('FAKE DISCONNECT ERROR');
				return {
					data: serialize({ message: { projectId }, status: 200 }),
				};
			};
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_RENDER_VARIANT_STATUS:
			return async (payload: MockPayload) => {
				await delay(1500);
				//@ts-ignore
				if (window.WEBSOCKET_CONNECTION == 'ERROR') throw new Error('FAKE DISCONNECT ERROR');
				return {
					data: serialize({
						message: {
							output: [
								{
									url: 'https://integration-mkinwt.pixcap.com/cdn/library/…29790173_3483b927-784e-4cde-a9dd-dc1f4c98b1b8.png',
									styleId: '1',
								},
								{
									url: 'https://integration-mkinwt.pixcap.com/cdn/categories/beauty.webp',
									styleId: '2',
								},
								{
									url: 'https://integration-mkinwt.pixcap.com/cdn/library/templates/4fc45aea-4104-440d-b11f-79162b78d232/thumbnail/sm_transparent_b2d6cec2-460a-4b6c-bdd6-2a0fba4704e8.png',
									styleId: '3',
								},
								{
									url: 'https://integration-mkinwt.pixcap.com/cdn/library/templates/62c1a01b-434e-4b88-828d-35608c039050/thumbnail/sm_transparent_e7ee5492-b853-48cd-beff-5753fd95f8e6.png',
									styleId: '4',
								},
							],
						},
						status: 200,
					}),
				};
			};

		default:
			logger.error(`No mock support for request response for websocket operation ${operation}`);
			return null;
	}
}
