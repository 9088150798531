export enum MessageEvents {
    DUPLICATE_PROJECT = 'DUPLICATE_PROJECT',
    SURVEY_COMPLETED = 'SURVEY_COMPLETED',
    LOGOUT_EVENT = 'LOGOUT_EVENT',
}

export type EditorMountedMessage = {
    action: MessageEvents;
    checkEditorProject: {
        activeProject: string;
        editorPageId: number;
    };
};

export interface IServiceWorkerManager {
    postLogoutMessage(): void
    postSurveyCompletedMessage(): void;
    postEditorMountedMessage(projectId: string, editorPageId: number): void
}