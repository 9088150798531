
	import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
	import IntersectComponent from '@pixcap/ui-core/plugins/intersect-component/IntersectComponent.vue';

	@Component({
		components: { IntersectComponent },
	})
	export default class IntersectVideoAutoplay extends Vue {
		@Prop() poster: string;
		@Prop() videoSources: { src: string; type: string }[];

		isIntersecting = false;

		$refs: {
			intersect: IntersectComponent;
			video: HTMLVideoElement;
		};

		@Watch('isIntersecting', { immediate: true })
		isIntersectingChange(newVal: boolean) {
			if (newVal) {
				this.$refs.video.load();
				this.$refs.intersect.observer.disconnect();
			}
		}
	}
