import { appUtilities } from '@pixcap/ui-core/modules/appUtilities';

export interface AppLogger {
	_isEnabled: boolean;
	isEnabled: boolean;
	log: (...messages: any[]) => void;
	warn: (...messages: any[]) => void;
	error: (...messages: any[]) => void;
	errorHandler: (...messages: any[]) => void;
}

const logger: AppLogger = {
	log: function () { },
	warn: function () { },
	errorHandler: function () { },
	error: function (...data: any[]) {
		appUtilities.$captureError(data);
	},
	_isEnabled: false,
	set isEnabled(isEnabled: boolean) {
		if (isEnabled) {
			const localLogger = console.error;
			this.log = console.log;
			this.warn = console.warn;
			this.error = function (...data: any[]) {
				localLogger(...data);
				appUtilities.$captureError(...data);
			};
			this.errorHandler = console.error;
		} else {
			this.log = function () { };
			this.warn = function () { };
			this.error = function (...data: any[]) {
				appUtilities.$captureError(...data);
			};
			this.errorHandler = function () { };
		}
		this._isEnabled = isEnabled;
	},
	get isEnabled() {
		return this._isEnabled;
	},
};

export default logger;
