import { PixRenderExportPayload, PixRenderExportStatus, PixRenderExportType } from '@pixcap/ui-core/models/store/projectexports.interface';

const DEFAULT_PROGRESS_STEPS_INFO = [
	{
		start: 0,
		end: 50,
		duration: 5 * 1000,
	},
	{
		start: 50,
		end: 80,
		duration: 10 * 1000,
	},
	{
		start: 80,
		end: 90,
		duration: 30 * 1000,
	},
	{
		start: 90,
		end: 97,
		duration: 15 * 1000,
	},
];

export class ServerRenderProgressSimulator {
	_serverExportItems: {
		[renderId: string]: {
			serverItemInfo: PixRenderExportPayload;
			progress: number;
			step: number;
			progressTimout: any;
			updateProgressCallback: any;
			stepsInfo?: { start: number; end: number; duration: number }[];
		};
	} = {};

	averageMinuteVideoExportDuration = 120;

	_stepsCallbacksMap = [
		this._handleProgressIntervalPart1Callback,
		this._handleProgressIntervalPart2Callback,
		this._handleProgressIntervalPart3Callback,
		this._handleProgressIntervalPart4Callback,
	];

	addOrUpdateServerExportItem(payload: PixRenderExportPayload, callback: any) {
		const renderId = payload.renderId;
		if (payload.status != PixRenderExportStatus.IN_PROGRESS && payload.status != PixRenderExportStatus.PREPARING) {
			payload.progress = 100;
			if (this._serverExportItems[renderId]?.progressTimout != null) clearTimeout(this._serverExportItems[renderId].progressTimout);
			callback(payload);
			return;
		}

		if (this._serverExportItems[renderId]) {
			payload.progress = this._serverExportItems[renderId].progress;
			this._serverExportItems[renderId].serverItemInfo = payload;
			this._serverExportItems[renderId].updateProgressCallback = callback;
		} else {
			const progress = payload.progress;
			const step = progress >= 50 ? 1 : 0;
			this._serverExportItems[renderId] = {
				serverItemInfo: payload,
				progress: progress >= 50 ? 50 : 0,
				step: progress >= 50 ? 1 : 0,
				progressTimout: null,
				updateProgressCallback: callback,
				stepsInfo: this._calculateStepsInfo(payload),
			};
			this._serverExportItems[renderId].serverItemInfo.progress = this._serverExportItems[renderId].progress;
			this._stepsCallbacksMap[step].call(this, renderId);
		}
	}

	updateRenderIdForExportItem(renderId: string, newRenderId: string) {
		clearTimeout(this._serverExportItems[renderId].progressTimout);
		this._serverExportItems[newRenderId] = { ...this._serverExportItems[renderId] };
		this._serverExportItems[newRenderId].serverItemInfo.renderId = newRenderId;
		this._stepsCallbacksMap[this._serverExportItems[renderId].step].call(this, newRenderId);
	}

	removeServerExportItem(renderId: string) {
		const serverExportItem = this._serverExportItems[renderId];
		if (serverExportItem.progressTimout) clearTimeout(serverExportItem.progressTimout);
		delete this._serverExportItems[renderId];
	}

	_handleProgressIntervalPart1Callback(renderId: string) {
		const step = 0;
		this._serverExportItems[renderId].step = step;
		this._serverExportItems[renderId].updateProgressCallback(this._serverExportItems[renderId].serverItemInfo);
		const stepInfo = this._serverExportItems[renderId].stepsInfo[step] || DEFAULT_PROGRESS_STEPS_INFO[step];
		const timeout = stepInfo.duration / (stepInfo.end - stepInfo.start);
		this._serverExportItems[renderId].progressTimout = setTimeout(() => {
			this._handleProgressTimeoutCallback(renderId, stepInfo.end, timeout, this._handleProgressIntervalPart2Callback);
		}, timeout);
	}
	_handleProgressIntervalPart2Callback(renderId: string) {
		const step = 1;
		this._serverExportItems[renderId].step = step;
		this._serverExportItems[renderId].updateProgressCallback(this._serverExportItems[renderId].serverItemInfo);
		const stepInfo = this._serverExportItems[renderId].stepsInfo[step] || DEFAULT_PROGRESS_STEPS_INFO[step];
		const timeout = stepInfo.duration / (stepInfo.end - stepInfo.start);
		this._serverExportItems[renderId].progressTimout = setTimeout(() => {
			this._handleProgressTimeoutCallback(renderId, stepInfo.end, timeout, this._handleProgressIntervalPart3Callback);
		}, timeout);
	}
	_handleProgressIntervalPart3Callback(renderId: string) {
		const step = 2;
		this._serverExportItems[renderId].step = step;
		this._serverExportItems[renderId].updateProgressCallback(this._serverExportItems[renderId].serverItemInfo);
		const stepInfo = this._serverExportItems[renderId].stepsInfo[step] || DEFAULT_PROGRESS_STEPS_INFO[step];
		const timeout = stepInfo.duration / (stepInfo.end - stepInfo.start);
		this._serverExportItems[renderId].progressTimout = setTimeout(() => {
			this._handleProgressTimeoutCallback(renderId, stepInfo.end, timeout, this._handleProgressIntervalPart4Callback);
		}, timeout);
	}
	_handleProgressIntervalPart4Callback(renderId: string) {
		const step = 3;
		this._serverExportItems[renderId].step = step;
		this._serverExportItems[renderId].updateProgressCallback(this._serverExportItems[renderId].serverItemInfo);
		const stepInfo = this._serverExportItems[renderId].stepsInfo[step] || DEFAULT_PROGRESS_STEPS_INFO[step];
		const timeout = stepInfo.duration / (stepInfo.end - stepInfo.start);
		this._serverExportItems[renderId].progressTimout = setTimeout(() => {
			this._handleProgressTimeoutCallback(renderId, stepInfo.end, timeout, null);
		}, timeout);
	}

	_handleProgressTimeoutCallback(renderId: string, maxProgress: number, timeout: number, finishCallback?: any) {
		this._serverExportItems[renderId].progress += 1;
		this._serverExportItems[renderId].serverItemInfo.progress = this._serverExportItems[renderId].progress;
		if (this._serverExportItems[renderId].progress > maxProgress) {
			if (finishCallback) finishCallback.call(this, renderId);
			else {
				clearTimeout(this._serverExportItems[renderId].progressTimout);
				this._serverExportItems[renderId].progressTimout = null;
			}
		} else {
			this._serverExportItems[renderId].updateProgressCallback(this._serverExportItems[renderId].serverItemInfo);
			this._serverExportItems[renderId].progressTimout = setTimeout(() => {
				this._handleProgressTimeoutCallback(renderId, maxProgress, timeout, finishCallback);
			}, timeout);
		}
	}

	_calculateStepsInfo(payload: PixRenderExportPayload) {
		if (payload.exportType == PixRenderExportType.IMAGE) return DEFAULT_PROGRESS_STEPS_INFO;
		const totalExportTime = (payload.animationDuration || 5) * this.averageMinuteVideoExportDuration; // assume that each second of video take 1 minute of exporting can be adjusted
		return [
			{
				start: 0,
				end: 50,
				duration: (totalExportTime / 12) * 1000,
			},
			{
				start: 50,
				end: 80,
				duration: (totalExportTime / 6) * 1000,
			},
			{
				start: 80,
				end: 90,
				duration: (totalExportTime / 2) * 1000,
			},
			{
				start: 90,
				end: 97,
				duration: (totalExportTime / 4) * 1000,
			},
		];
	}
}
