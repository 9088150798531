import { WEBSOCKET_OPERATIONS } from '../WebSocketManager';
import { MockPayload } from '@pixcap/ui-core/services/socketmanager/mocks/MockRSocket';
import { delay, serialize } from '@pixcap/ui-core/services/socketmanager/mocks/MockUtils';
import logger from '@pixcap/ui-core/helpers/logger';

export function getMockedRequestStream(operation: WEBSOCKET_OPERATIONS) {
	const now = new Date().toISOString();
	switch (operation) {
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_NOTIFICATIONS_LIST:
			return async (payload: MockPayload) => {
				logger.log('SUBSCRIBE_NOTIFICATIONS_LIST payload', payload);
				await delay(3000);
				return {
					data: serialize({
						message: {
							userNotificationId: '3ee36dea-2bfc-4750-a4a8-07a9b32f20d6',
							notificationType: 'USER_STYLE_LIKED',
							notificationText: 'Loved your publish',
							displayName: 'nouman+3',
							avatarUrl: 'https://integration-mkinwt.pixcap.com/cdn/notifications/pixcap.png',
							notificationIcons: [],
							displayTime: '2 seconds ago',
							createdAt: '2023-12-12T11:37:11.973Z',
						},
						status: 200,
					}),
					isComplete: false, //only injected for mocking purposes
				};
			};
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE:
			return async (payload: MockPayload) => {
				logger.log('SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE payload', payload);
				await delay(3000);
				return {
					data: serialize({ message: { userId: 'USER_ID_001' }, status: 200 }),
					isComplete: false, //only injected for mocking purposes
				};
			};
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_USER_SESSIONS:
			return async (payload: MockPayload) => {
				logger.log('SUBSCRIBE_USER_SESSIONS payload', payload);
				await delay(3000);
				return {
					data: serialize({ message: { userId: 'USER_ID_001', sessionId: 'SESSION_ID', action: "logout" }, status: 200 }),
					isComplete: false, //only injected for mocking purposes
				};
			};
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_CREDITS_CHANGE:
			return async (payload: MockPayload) => {
				logger.log('SUBSCRIBE_CREDITS_CHANGE payload', payload);
				await delay(3000);
				return {
					data: serialize({ message: { userId: 'USER_ID_001' }, status: 200 }),
					isComplete: false, //only injected for mocking purposes
				};
			};
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_RENDER_PROGRESS:
			return async (payload: MockPayload) => {
				logger.log('SUBSCRIBE_RENDER_PROGRESS payload', payload);
				await delay(3000);
				return {
					data: serialize({
						progress: { userId: 'USER_ID_001', renderId: 'RENDER_ID', progress: '10 %', status: 'running', fileName: 'Mock file' },
						status: 200,
					}),
					isComplete: false, //only injected for mocking purposes
				};
			};
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_RENDER_VARIANT_STATUS:
			return async (payload: MockPayload) => {
				await delay(1500);
				//@ts-ignore
				if (window.WEBSOCKET_CONNECTION == 'ERROR') throw new Error('FAKE DISCONNECT ERROR');
				return {
					data: serialize({
						message: {
							output: [
								{
									url: 'https://integration-mkinwt.pixcap.com/cdn/library/…29790173_3483b927-784e-4cde-a9dd-dc1f4c98b1b8.png',
									styleId: '1',
								},
								{
									url: 'https://integration-mkinwt.pixcap.com/cdn/categories/beauty.webp',
									styleId: '2',
								},
								{
									url: 'https://integration-mkinwt.pixcap.com/cdn/library/templates/4fc45aea-4104-440d-b11f-79162b78d232/thumbnail/sm_transparent_b2d6cec2-460a-4b6c-bdd6-2a0fba4704e8.png',
									styleId: '3',
								},
								{
									url: 'https://integration-mkinwt.pixcap.com/cdn/library/templates/62c1a01b-434e-4b88-828d-35608c039050/thumbnail/sm_transparent_e7ee5492-b853-48cd-beff-5753fd95f8e6.png',
									styleId: '4',
								},
							],
						},
						status: 200,
					}),
					isComplete: false, //only injected for mocking purposes
				};
			};
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_PROJECT_USERS:
			const mocks = [];
			const avatars = ['6wdhPe6iTnB', '7x6aZmh2JNI', '5v5j_lqOHTO', '9BUk7hTIGzO', null, null];
			for (let i = 0; i < 8; i++) {
				mocks.push(async (payload: MockPayload) => {
					await delay(100);
					return {
						data: serialize({
							user: JSON.stringify({
								projectId: payload.data.projectId,
								userId: `USER_ID_${i}`,
								displayName: `DISPLAY_NAME_${i}`,
								email: `EMAIL_${i}`,
								avatarUrl: avatars[i] ? `https://integration-mkinwt.pixcap.com/cdn/library/thumbnails/poly/${avatars[i]}.webp` : 'none',
								updatedAt: now,
							}),
							action: 'ADD',
						}),
						isComplete: false, //only injected for mocking purposes
					};
				});
			}
			mocks.push(async (payload: MockPayload) => {
				await delay(200);
				return {
					data: serialize({
						user: JSON.stringify({
							projectId: payload.data.projectId,
							userId: `USER_ID_0`,
							email: `EMAIL_0`,
							avatarUrl: 'none',
							updatedAt: now,
						}),
						action: 'REMOVE',
					}),
					isComplete: false, //only injected for mocking purposes
				};
			});
			return mocks;
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_PROJECT_COMMENTS:
			return [
				async (payload: MockPayload) => {
					await delay(100);
					return {
						data: serialize({
							comment: JSON.stringify({
								author: {
									displayName: `User socket comment`,
									avatarUrl: '',
									userId: 'USER_ID_HERE',
								},
								projectId: 'PROJECT_ID',
								comment: 'Here socket comment',
								commentId: 'COMMENT_ID_HERE',
								threadCount: 0,
								isEdited: false,
								isLocked: false,
								createdAt: now,
								updatedAt: now,
							}),
							action: 'Add',
						}),
						isComplete: false, //only injected for mocking purposes
					};
				},
				async (payload: MockPayload) => {
					await delay(300);
					return {
						data: serialize({
							comment: JSON.stringify({
								author: {
									displayName: `User socket comment`,
									avatarUrl: '',
									userId: 'USER_ID_HERE',
								},
								projectId: 'PROJECT_ID',
								comment: 'Here socket comment reply',
								parentCommentId: 'COMMENT_ID_HERE',
								commentId: 'COMMENT_ID_HERE_REPLY_01',
								threadCount: 0,
								isEdited: false,
								isLocked: false,
								createdAt: now,
								updatedAt: now,
							}),
							action: 'Add',
						}),
						isComplete: false, //only injected for mocking purposes
					};
				},
				async (payload: MockPayload) => {
					await delay(400);
					return {
						data: serialize({
							comment: JSON.stringify({
								author: {
									displayName: `User socket comment`,
									avatarUrl: '',
									userId: 'USER_ID_HERE',
								},
								projectId: 'PROJECT_ID',
								comment: 'Here socket update me here',
								commentId: 'COMMENT_ID_HERE',
								threadCount: 1,
								isEdited: false,
								isLocked: false,
								createdAt: now,
								updatedAt: now,
							}),
							action: 'Edit',
						}),
						isComplete: false, //only injected for mocking purposes
					};
				},
			];
		case WEBSOCKET_OPERATIONS.SUBSCRIBE_SHARED_PROJECTS:
			return [
				async (payload: MockPayload) => {
					await delay(500);
					return {
						data: serialize({
							project: JSON.stringify({
								projectId: 'SHARED_PROJECT_ID',
								userId: 'SHARED_USER_ID',
								projectTitle: 'Shared socket project',
								thumbnailFileId: '',
								thumbnailUrl: '',
								createdAt: now,
								updatedAt: now,
							}),
							action: 'ADDED',
						}),
						isComplete: false, //only injected for mocking purposes
					};
				},
				async (payload: MockPayload) => {
					await delay(1000);
					return {
						data: serialize({
							project: JSON.stringify({
								projectId: 'cb0553b8-120e-4526-9fa7-4c01a765cf2e',
								userId: 'SHARED_USER_ID',
								projectTitle: 'Shared socket project',
								thumbnailFileId: '',
								thumbnailUrl: '',
								createdAt: now,
								updatedAt: now,
							}),
							action: 'Removed',
						}),
						isComplete: false, //only injected for mocking purposes
					};
				},
			];

		case WEBSOCKET_OPERATIONS.SUBSCRIBE_FBX_EXPORTS:
			return async (payload: MockPayload) => {
				logger.log('SUBSCRIBE_FBX_EXPORTS payload', payload);
				await delay(1000);
				return {
					data: serialize({
						exportFbx: JSON.stringify({
							exportName: 'exportName.fbx',
							exportId: 'EXPORT_ID_SOCKET',
							fbxFileId: null,
							projectId: 'PROJECT_ID',
							isFailed: false,
							errorMessage: null,
						}),
					}),
					isComplete: false, //only injected for mocking purposes
				};
			};

		default:
			logger.error(`No mock support for request response for websocket operation ${operation}`);
			return null;
	}
}
