export interface ILocalStorageManager {
	write(storageKey: string, data: any): void;
	remove(storageKey: string): void;
	read(storageKey: string): any;

	writeSession(storageKey: string, data: any): void;
	removeSession(storageKey: string): void;
	readSession(storageKey: string): any;
}
export class LocalStorageManager implements ILocalStorageManager {
	write(storageKey: string, data: any): void {
		localStorage.setItem(storageKey, JSON.stringify(data));
	}
	read(storageKey: string): any {
		const data = localStorage.getItem(storageKey);
		return JSON.parse(data);
	}
	remove(storageKey: string): void {
		localStorage.removeItem(storageKey);
	}

	writeSession(storageKey: string, data: any) {
		sessionStorage.setItem(storageKey, JSON.stringify(data));
	}

	readSession(storageKey: string) {
		const data = sessionStorage.getItem(storageKey);
		return JSON.parse(data);
	}

	removeSession(storageKey: string) {
		sessionStorage.removeItem(storageKey);
	}
}

let localStorageManager: ILocalStorageManager = new LocalStorageManager();

export { localStorageManager };
