export type Question = {
	questionId: string;
	title: string;
	description: string;
	isMultiSelect: boolean;
	options: { name: string; description?: string; illustration: string | undefined; questions?: Question[] }[];
	skippable: boolean;
};

export interface ISurvey {
	questions: Question[];
	isLoadingSurvey: boolean;
	showOnboardingModal: boolean;
}

export const NAMESPACE = 'survey';

export enum MutationTypes {
	SET_IS_LOADING_SURVEY = 'SET_IS_LOADING_SURVEY',
	SET_SHOW_ONBOARDING_MODAL = 'SET_SHOW_ONBOARDING_MODAL',
	SET_QUESTIONS_LIST = 'SET_QUESTIONS_LIST',
}

export enum ActionTypes {
	GET_SURVEY_DATA = 'GET_SURVEY_DATA',
	SUBMIT_SURVEY_ANSWERS = 'SUBMIT_SURVEY_ANSWERS',
}
