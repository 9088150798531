import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { NAMESPACE as AUTH_NAMESPACE, GetterTypes as AuthGetterTypes, Getters as AuthGetters, IAuthState } from '@pixcap/ui-core/models/store/auth.interface';
import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';

@Component({
	name: 'AuthenticationModalMixin',
	computed: {
		...mapState(AUTH_NAMESPACE, {
			isRefreshingToken: (state: IAuthState) => state.isRefreshingToken
		}),
		...mapGetters(AUTH_NAMESPACE, {
			isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
		})
	},
})
export default class AuthenticationModalMixin extends Vue {
	isAuthenticated: ReturnType<AuthGetters[AuthGetterTypes.IS_AUTHENTICATED]>;
	isRefreshingToken: IAuthState['isRefreshingToken']
	showAuthenticationModal() {
		UserMutations.showAuthenticationModal(this.$store, true);
	}
}
