import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { extractEncryptionMetadata } from '@pixcap/ui-core/utils/EncryptUtils';

const CancelToken = axios.CancelToken;

export enum CANCELLABLE_REQUEST_POOL {
	FETCH_PAGINATED_LIBRARY_ITEMS = 'FETCH_PAGINATED_LIBRARY_ITEMS',
	FETCH_LIBRARY_ITEM = 'FETCH_LIBRARY_ITEM',
	FETCH_PAGINATED_PREMADE_PROJECTS = 'FETCH_PAGINATED_PREMADE_PROJECTS',
	FETCH_PROJECTS = 'FETCH_PROJECTS',
}

export type CancelConfig = {
	cancellableRequestPool: CANCELLABLE_REQUEST_POOL;
	isCancelPriors?: boolean;
};

export class BaseHttpClient {
	protected _client: AxiosInstance;

	private _cancelTokensCache: Partial<Record<CANCELLABLE_REQUEST_POOL, CancelTokenSource>> = {};

	constructor(config?: AxiosRequestConfig) {
		this._client = axios.create(config);
	}

	static Create() {
		return new BaseHttpClient();
	}

	get clientInstance() {
		return this._client;
	}

	getUri(config?: AxiosRequestConfig): string {
		return this._client.getUri(config);
	}

	request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
		return this._client.request(config);
	}

	/**
	 * Provide cancelConfig if you want to pool requests together such that if another request subsequently arrives and has isCancelPriors to be true,
	 * then older requests within the pool will be cancelled.
	 * Useful for when multiple of the same requests are made but we only care about the last occurring request.
	 */

	async getWithEncrypt(
		url: string,
		config?: AxiosRequestConfig,
		cancelConfig?: CancelConfig
	): Promise<any & { encryptKey?: string; encryptIv?: string }> {
		const response = await this.get(url, config, cancelConfig);
		const { encryptKey, encryptIv } = extractEncryptionMetadata(response.data);
		return { ...response.data, encryptKey, encryptIv };
	}

	get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig, cancelConfig?: CancelConfig): Promise<R> {
		let cancelToken;
		if (cancelConfig) {
			cancelToken = this._registerCancelToken(cancelConfig.cancellableRequestPool, cancelConfig.isCancelPriors);
		}
		return this._client.get(url, { ...config, cancelToken });
	}

	delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
		return this._client.delete(url, config);
	}

	head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
		return this._client.head(url, config);
	}

	options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
		return this._client.options(url, config);
	}

	post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
		return this._client.post(url, data, config);
	}

	put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
		return this._client.put(url, data, config);
	}

	patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
		return this._client.patch(url, data, config);
	}


	private _registerCancelToken(reqPool: CANCELLABLE_REQUEST_POOL, isCancelPriors = true) {
		if (this._cancelTokensCache[reqPool] && isCancelPriors) {
			const cancelTokenSrc = this._cancelTokensCache[reqPool];
			cancelTokenSrc?.cancel(`Cancel pre-existing request for ${reqPool} request pool`);
			delete this._cancelTokensCache[reqPool];
		}

		let cancelToken;
		if (this._cancelTokensCache[reqPool]) {
			const cancelTokenSrc = this._cancelTokensCache[reqPool];
			cancelToken = cancelTokenSrc?.token;
		} else {
			const cancelTokenSrc = CancelToken.source();
			this._cancelTokensCache[reqPool] = cancelTokenSrc;
			cancelToken = cancelTokenSrc.token;
		}

		return cancelToken;
	}
}
