export enum LOGIN_STEPS {
	EMAIL_FORM,
	LOGIN_FORM,
	EMAIL_VERIFICATION_FORM,
	RESET_PASSWORD_FORM,
	RESET_CODE_FORM,
	CREATE_ACCOUNT_FORM,
}

export type AuthInfo = {
	userId: string;
	idToken: string;
};

export type IAuthState = {
	idToken: string;
	isSubmitting: boolean;
	loginEmail: string;
	resetPasswordCode: string;
	isRefreshingToken: boolean;
	anonymousSession?: { session: string; project: string };
};

export const NAMESPACE = 'auth';

export enum GetterTypes {
	IS_AUTHENTICATED = 'isAuthenticated',
}

export enum MutationTypes {
	STORE = 'STORE',
	REFRESH = 'REFRESH',
	LOGOUT = 'LOGOUT',
	UPDATE_LOGIN_EMAIL = 'UPDATE_LOGIN_EMAIL',
	UPDATE_RESET_PASSWORD_CODE = 'UPDATE_RESET_PASSWORD_CODE',
	UPDATE_SUBMIT_STATUS = 'UPDATE_SUBMIT_STATUS',
	SET_ANONYMOUS_SESSION = 'SET_ANONYMOUS_SESSION',
	SET_IS_REFRESHING_TOKEN = 'SET_IS_REFRESHING_TOKEN',
}

export enum ActionTypes {
	REFRESH = 'REFRESH',
	LOGOUT = 'LOGOUT',
	RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION',
	VERIFY_EMAIL = 'VERIFY_EMAIL',
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
	STORE = 'STORE',
	SIGN_IN = 'SIGN_IN',
	SIGN_UP = 'SIGN_UP',
	CHECK_EMAIL_UNIQUENESS = 'CHECK_EMAIL_UNIQUENESS',
	VALIDATE_REFERRAL_CODE = 'VALIDATE_REFERRAL_CODE',
	VALIDATE_REFERRAL_EMAIL = 'VALIDATE_REFERRAL_EMAIL',
	FETCH_INVITED_REFEREE = 'FETCH_INVITED_REFEREE',
	FETCH_PROMOTIONS = 'FETCH_PROMOTIONS',
	FORGET_PASSWORD = 'FORGET_PASSWORD',
	RESET_PASSWORD = 'RESET_PASSWORD',
	SEND_AUTH_CODE = 'SEND_AUTH_CODE',
	SYNC_ANONYMOUS_SESSION_WITH_USER = 'SYNC_ANONYMOUS_SESSION_WITH_USER',
	GRANT_FIGMA_ACCESS = 'GRANT_FIGMA_ACCESS',
}

export type Getters<S = IAuthState> = {
	isAuthenticated: (state: S) => boolean;
};
