
	import { Component, Vue } from 'vue-property-decorator';
	@Component({
		name: 'IntersectComponent',
	})
	export default class IntersectComponent extends Vue {
		observer: any = null;

		render() {
			return this.$slots.default ? this.$slots.default[0] : null;
		}

		mounted() {
			this.observer = new IntersectionObserver(
				(entries) => {
					if (!entries[0].isIntersecting) {
						this.$emit('leave');
					} else {
						this.$emit('enter');
					}
				},
				{
					threshold: 0.2,
					rootMargin: '0px 0px 0px 0px',
				}
			);
			this.$nextTick(() => {
				this.observer.observe(this.$slots.default[0].elm);
			});
		}

		beforeDestroy() {
			this.observer.disconnect();
		}
	}
