export enum DROP_ZONE_SECTION {
	CANVAS = 'CANVAS', // drag asset to canvas
	CUSTOMIZER_CANVAS = 'CUSTOMIZER_CANVAS',
}

export enum DRAG_ZONE_SECTION {
	ASSET_FILE = 'ASSET_FILE',
	ANIMATION = 'ANIMATION',
	CUSTOMIZER_ELEMENT = 'CUSTOMIZER_ELEMENT',
	CUSTOMIZER_UPLOADS = 'CUSTOMIZER_UPLOADS',
	CUSTOMIZER_AI_RESULTS = 'CUSTOMIZER_AI_RESULTS',
}

export type DragAssets = {
	fileId: string;
	assetName: string;
	type: any;
	isFromLibrary?: boolean;
}[];

export type DragAnimation = {
	name: string;
	animType: any;
	animationIdentifier: string;
	fileId: string;
	isFromLibrary?: boolean;
};

export type CustomizerElement = {
	itemId?: string;
	fileId?: string;
	templateId?: string;
	itemName?: string;
	isAnimation: boolean;
	skeletonNodeId: string;
};

export type CustomizerUpload = {
	nodeId?: string;
	targetName?: string;
	thumbnailUrl?: string;
	type?: number;
	protected?: boolean;
	createdAt?: string;
	updatedAt?: string;
	fileId?: string;
	progress?: number;
};

export type DragAndDropContext = {
	[DROP_ZONE_SECTION.CANVAS]?: {
		[DRAG_ZONE_SECTION.ANIMATION]?: DragAnimation;
		[DRAG_ZONE_SECTION.ASSET_FILE]?: DragAssets;
	};
	[DROP_ZONE_SECTION.CUSTOMIZER_CANVAS]?: {
		[DRAG_ZONE_SECTION.CUSTOMIZER_ELEMENT]?: CustomizerElement;
		[DRAG_ZONE_SECTION.CUSTOMIZER_UPLOADS]?: CustomizerUpload;
	};
};

export interface IDragAndDropManager {
	initDrag: (sourceDragZone, targetDropZone, dragObject) => void;
	finishDrag(sourceDragZone, targetDropZone): void;
	getDragObject: (sourceDragZone, targetDropZone) => any;
	updateDragImage(payload: { imgUrl?: string; width?: number; height?: number }): void;
	deleteDragImage(): void;
	setDragImage(payload: { imgUrl: string; e: DragEvent }): void;
	getDragImageSize(): { x: number; y: number; w: number; h: number };
}
