import type { Store } from 'vuex';
import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
import { MockRSocketClient } from './mocks/MockRSocket';
import { WebSocketManager } from './WebSocketManager';

export function socketManagerFactory(store: Store<any>): WebSocketManager {
	if (WebSocketManager.lastInitSocketManager) return WebSocketManager.lastInitSocketManager;

	const webSocketManager = new WebSocketManager(store);
	if (_PIXCAP_ENV.IS_LOCAL_APP_ENV) {
		//@ts-ignore
		webSocketManager._createClient = () => {
			return new MockRSocketClient();
		};
	}
	return webSocketManager;
}

export * from './WebSocketManager';
