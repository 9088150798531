import { getMockedRequestResponse } from './MockedRequestResponse';
import { toBuffer } from 'rsocket-core';
import { getMockedRequestStream } from './MockedRequestStream';
import logger from '@pixcap/ui-core/helpers/logger';

// DEBUGGING FLAGS:
// set to true to test WebSocketManager autoreconnect behaviour
const IS_DISCONNECT_ERROR = false;
// set to true to test internal server error of request
const IS_REQUEST_ERROR = false;
// set to true to test invalid jwt error, i.e. status code 401
const IS_INVALID_JWT = false;
// set to true to simulate socket error after an amount of time
const IS_ERROR_FOR_A_WHILE = false;

// change window.WEBSOCKET_CONNECTION to 'CONNECTED' | 'ERROR' | 'CLOSED' in console to toggle socket connection

type ConnectionStatus = {
	kind: 'CONNECTED' | 'ERROR' | 'CLOSED';
};

type MockFlowable<T> = {
	onNext?: (data: T) => void;
	onSubscribe?: ({ cancel, request }) => void;
	onComplete?: () => void;
	onError?: (e: Error) => void;
};

type MockSingle<T> = {
	onComplete?: (data: T) => void;
	onError?: (e: Error) => void;
};

export type MockPayload = { data: any; metadata: any };

let lastConnectionStatus;

class MockReactiveSocket {
	connectionStatus() {
		return {
			subscribe({ onNext }: MockFlowable<ConnectionStatus>) {
				const connectionCheckInternval = setInterval(() => {
					//@ts-ignore
					const WEBSOCKET_CONNECTION = window.WEBSOCKET_CONNECTION || 'CONNECTED';

					if (lastConnectionStatus && lastConnectionStatus == WEBSOCKET_CONNECTION) return;

					switch (WEBSOCKET_CONNECTION) {
						case 'CLOSED':
							lastConnectionStatus = 'CLOSED';
						case 'ERROR':
							lastConnectionStatus = 'ERROR';
							// should not clear the interval

							{
								const connectedStatus: ConnectionStatus = {
									kind: WEBSOCKET_CONNECTION,
								};
								onNext(connectedStatus);
							}
							break;
						default: {
							lastConnectionStatus = 'CONNECTED';
							const connectedStatus: ConnectionStatus = {
								kind: 'CONNECTED',
							};
							onNext(connectedStatus);
						}
					}
				}, 3000);
			},
		};
	}

	requestStream(payload: MockPayload) {
		const data = JSON.parse(payload.data.toString());
		const metadata = JSON.parse(payload.metadata.toString());

		return {
			async subscribe({ onNext, onSubscribe, onComplete, onError }: MockFlowable<any>) {
				const cancelFunc = () => {
					logger.log(`Cancel performed for request stream of operation: ${metadata.operation}`);
				};
				onSubscribe({ cancel: cancelFunc, request: () => {} });

				if (IS_REQUEST_ERROR) {
					const error = new Error('Fake request error');
					onError(error);
				} else if (IS_INVALID_JWT) {
					const invalidJwtResponse = {
						data: toBuffer(JSON.stringify({ status: 401, message: 'Invalid JWT' })),
					};
					await onNext(invalidJwtResponse);
					await onComplete();
				} else {
					const mocks = getMockedRequestStream(metadata.operation);
					const deserialisedPayload = {
						data,
						metadata,
					};
					if (Array.isArray(mocks)) {
						for (const mock of mocks) {
							const response = await mock(deserialisedPayload);
							// isComplete is only injected for mocking purposes so the MockRSocket knows to call onComplete upon receipt of the last payload
							if (response.isComplete) {
								await onNext(response);
								await onComplete();
							} else {
								await onNext(response);
							}
						}
					} else {
						const response = await mocks(deserialisedPayload);
						// isComplete is only injected for mocking purposes so the MockRSocket knows to call onComplete upon receipt of the last payload
						if (response.isComplete) {
							await onNext(response);
							await onComplete();
						} else {
							await onNext(response);
						}
					}

					if (IS_ERROR_FOR_A_WHILE) {
						setTimeout(() => {
							const error = new Error('Fake request error');
							onError(error);
						}, 10000);
					}
				}
			},
		};
	}

	requestResponse(payload: MockPayload) {
		// here is where you deconstruct the payload, define the mocks, and pass the payload into them
		// the response should then be passed to the mocked single

		const data = JSON.parse(payload.data.toString());
		const metadata = JSON.parse(payload.metadata.toString());

		return {
			async subscribe({ onComplete, onError }: MockSingle<any>) {
				if (IS_REQUEST_ERROR) {
					const error = new Error('Fake request error');
					onError(error);
				} else if (IS_INVALID_JWT) {
					const invalidJwtResponse = {
						data: toBuffer(JSON.stringify({ status: 401, message: 'Invalid JWT' })),
					};
					await onComplete(invalidJwtResponse);
				} else {
					const mock = getMockedRequestResponse(metadata.operation);
					const deserialisedPayload = {
						data,
						metadata,
					};
					const response = await mock(deserialisedPayload);
					await onComplete(response);
				}
			},
		};
	}

	close() {}
}

export class MockRSocketClient {
	connect() {
		return {
			subscribe({ onComplete, onError }: MockSingle<MockReactiveSocket>) {
				const reactiveSocket = new MockReactiveSocket();

				if (IS_DISCONNECT_ERROR) {
					onError(new Error('Fake Disconnect Error'));
				} else {
					onComplete(reactiveSocket);
				}
			},
		};
	}

	close() {}
}
