export interface IReadyPlayerMe {
    showReadyPlayerMeModal: boolean
}

export enum MutationTypes {
    SET_SHOW_READY_PLAYER_ME_MODAL = 'SET_SHOW_READY_PLAYER_ME_MODAL',
}

export enum ActionTypes {
    SAVE_RPM_AVATAR = 'SAVE_RPM_AVATAR'
}


export const NAMESPACE = 'readyplayerme'