export enum EVENTS_MANAGER_COMPONENT {
	CANVAS = '3D_VIEWPORT',
	ASSETS_MANAGER = 'ASSETS_MANAGER',
	CURVE_EDITOR = 'CURVE_EDITOR',
	INSPECTOR = 'INSPECTOR',
	PICKERS = 'PICKERS',
	LAYERS = 'LAYERS',
	SCENE_TREE = 'SCENE_TREE',
	RETARGETING = 'RETARGETING',
	IK = 'IK',
	DEFAULT = 'DEFAULT',
	ASSETS_ANIMATIONS_TAB = 'ASSETS_ANIMATIONS_TAB',
	ASSETS_FILES_TAB = 'ASSETS_FILES_TAB',
}

// only these events can be registered in vue templates
export enum EventType {
	MOUSE_DOWN = 'mousedown',
	RESIZE = 'resize',
	WHEEL = 'wheel',
	MOUSE_UP = 'mouseup',
	BLUR = 'blur',
	BEFORE_UNLOAD = 'beforeunload',
	CLICK = 'click',
	PASTE = 'paste'
}

export type EventHandler = {
	callback: (event: any) => any;
	domain: EVENTS_MANAGER_COMPONENT;
	callbackName: string;
};

export type EventListeners = {
	[k: string]: EventHandler[];
};

export interface IWindowEventsManager {
	registerEventListener(event: EventType, eventHandler: EventHandler, options?): void;
	deregisterEventListener(event: EventType, domain: EVENTS_MANAGER_COMPONENT, callbackName: string): void;
	switchActiveComponent(newActiveComponent: EVENTS_MANAGER_COMPONENT): void;
	getActiveComponent(): EVENTS_MANAGER_COMPONENT;
	reset(): void;
}
