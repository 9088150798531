import { Store } from 'vuex';
import { NAMESPACE as EXPORT_NAMESPACE, IExportState, PixRenderExportStatus } from '@pixcap/ui-core/models/store/projectexports.interface';
import { ActionTypes as AuthActionTypes, IAuthState, NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';
import { actionsWrapper as ExportActions } from '@pixcap/ui-core/store/projectexports';

export default class ServerRenderManager {
	private _worker: SharedWorker;
	private _store: Store<any>;
	static instance: ServerRenderManager;

	constructor(store: Store<any>) {
		ServerRenderManager.instance = this;
		this._store = store;
	}

	registerWebWorker() {
		this._worker = new SharedWorker('/cdn/workers/server-render-status.worker.js');
		this._worker.port.onmessage = async (e) => {
			const data = e.data;
			switch (data.action) {
				case 'refreshToken':
					await this._store.dispatch(`${AUTH_NAMESPACE}/${AuthActionTypes.REFRESH}`, undefined, { root: true });
					this.sendQueueRenderIdsToWorker(true);
					break;
				case 'renderIdCompleted':
					if (!data.downloaded) {
						// Wait short time to make sure no other active tab downloading duplicate file.
						setTimeout(() => {
							ExportActions.downloadServerRenderFileByRenderId(this._store, {
								renderId: data.renderId,
								downloadIfNotDownloadedAlready: true,
							});
						}, 3000);
					}
					break;
				default:
					console.error('Unhandled event:', data);
			}
		};
		this._worker.port.start();
		if (window) window.addEventListener('beforeunload', this.terminateWorker);
		this.sendQueueRenderIdsToWorker();
	}

	sendQueueRenderIdsToWorker(isRetry = false) {
		const currentRenderIds = (this._store.state[EXPORT_NAMESPACE] as IExportState).serverExportItems
			.filter((item) => !item.isPreviewFileRender && item.status === PixRenderExportStatus.IN_PROGRESS)
			.map((serverExportItem) => serverExportItem.renderId);
		const idToken = (this._store.state[AUTH_NAMESPACE] as IAuthState).idToken;
		this._worker.port.postMessage({ action: 'fetchRenderStatus', renderIds: currentRenderIds, idToken: idToken, isRetry });
	}

	terminateWorker() {
		if (this._worker) {
			this._worker.port.postMessage({ action: 'terminate' });
			this._worker = null;

			if (window) window.removeEventListener('beforeunload', this.terminateWorker);
		}
	}
}
