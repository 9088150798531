import { NAMESPACE as USER_NAMESPACE, MutationTypes as UserMutationTypes } from '@pixcap/ui-core/models/store/user.interface';
import { mutationsWrapper as SurveyMutations } from '@pixcap/ui-core/store/survey';
import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
import type VueRouter from 'vue-router/types';
import logger from '@pixcap/ui-core/helpers/logger';
import type { IServiceWorkerManager, EditorMountedMessage } from '@pixcap/ui-core/models/services/serviceWorkerManager.interface';
import { MessageEvents } from '@pixcap/ui-core/models/services/serviceWorkerManager.interface';
import type { Store } from 'vuex';

import { HttpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
const { NOTIFICATIONS_PATH } = API_PATHS;

export class ServiceWorkerManager implements IServiceWorkerManager {
	private _appRouter: VueRouter = null;
	private _store: Store<any>;
	constructor(router, store, isLocal = false) {
		this._appRouter = router;
		this._store = store;
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.addEventListener('message', (event) => {
				const data = event.data;
				switch (data.action) {
					case MessageEvents.DUPLICATE_PROJECT:
						this._duplicateEditorProjectEventCallback(event.data);
						break;
					case MessageEvents.LOGOUT_EVENT:
						this._logoutAllSessions();
						break;
					case MessageEvents.SURVEY_COMPLETED:
						this._closeSurveyModal();
						break;
					default:
						break;
				}
			});

			window.addEventListener('load', function () {
				navigator.serviceWorker.register('/cdn/workers/service-worker.js', { scope: isLocal ? '/cdn/workers/' : '/' }).then(
					function (registration) {
						logger.log('ServiceWorker registration successful with scope: ', registration.scope);
					},
					function (err) {
						logger.log('ServiceWorker registration failed: ', err);
					}
				);
				if ('PushManager' in window) {
					Notification.requestPermission(function (consent) {
						if (consent === 'granted') {
							navigator.serviceWorker.register('/cdn/workers/webpush.worker.js', { scope: '/' }).then(function (swRegistration) {
								logger.log('WebPush service worker registered with scope:', swRegistration.scope);
							});

							navigator.serviceWorker.ready.then(function (serviceWorker) {
								serviceWorker.pushManager.getSubscription().then((pushSubscription) => {
									async function addSubscription(userSubscription: PushSubscription) {
										await (store.$httpClient as HttpClient).post(`${NOTIFICATIONS_PATH}/subscription`, userSubscription);
									}
									if (!pushSubscription) {
										function getPublicKey() {
											const vapidKey = _PIXCAP_ENV.WEBPUSH_VAPID_KEY;
											const padding = '='.repeat((4 - (vapidKey.length % 4)) % 4);
											const base64 = (vapidKey + padding).replace(/-/g, '+').replace(/_/g, '/');
											const rawData = window.atob(base64);
											const outputArray = new Uint8Array(rawData.length);
											for (let i = 0; i < rawData.length; ++i) {
												outputArray[i] = rawData.charCodeAt(i);
											}
											return outputArray;
										}

										serviceWorker.pushManager
											.subscribe({
												userVisibleOnly: true,
												applicationServerKey: getPublicKey(),
											})
											.then(function (subscription) {
												logger.log('User is subscribed.', subscription);
												addSubscription(subscription);
											});
									} else {
										addSubscription(pushSubscription);
									}
								});
							});
						} else {
							logger.log('Notification permission denied.');
						}
					});
				}
			});
		}
	}

	/**
	 * If app is opened on editor page and activeProject is the same as the projectId, then redirect to dashboard
	 */
	private _duplicateEditorProjectEventCallback(data: EditorMountedMessage) {
		const router = this._appRouter;
		const { checkEditorProject } = data;
		if (checkEditorProject != null && checkEditorProject.editorPageId != sessionStorage.editorPageId) {
			if (router.currentRoute.name == 'Editor') {
				const { activeProject } = checkEditorProject;
				const projectId = router.currentRoute.params.projectUUID;
				if (projectId == activeProject) {
					setTimeout(function () {
						window.location.href = window.location.origin;
					}, 3000);
				}
			}
		}
	}

	/**
	 *  if user logout all open tab should redirec to login page
	 */

	private _logoutAllSessions() {
		const router = this._appRouter;
		if (router.currentRoute.meta.isPublicRoute) {
			this._store.commit(`${USER_NAMESPACE}/${UserMutationTypes.UPDATE_PERSONAL_DATA}`, undefined, { root: true });
		} else window.location.href = _PIXCAP_ENV.LOGIN_URL;
	}

	private _closeSurveyModal() {
		SurveyMutations.setShowOnboardingModal(this._store, false);
	}

	postSurveyCompletedMessage() {
		const message = {
			action: MessageEvents.SURVEY_COMPLETED,
		};
		if (navigator.serviceWorker.controller) navigator.serviceWorker.controller.postMessage(message);
	}

	postLogoutMessage() {
		const message = {
			action: MessageEvents.LOGOUT_EVENT,
		};
		this._logoutAllSessions();
		if (navigator.serviceWorker.controller) navigator.serviceWorker.controller.postMessage(message);
	}

	postEditorMountedMessage(projectId: string, editorPageId: number) {
		const message: EditorMountedMessage = {
			action: MessageEvents.DUPLICATE_PROJECT,
			checkEditorProject: {
				activeProject: projectId,
				editorPageId: editorPageId,
			},
		};
		if (navigator.serviceWorker.controller) navigator.serviceWorker.controller.postMessage(message);
	}
}
