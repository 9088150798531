import { SubscriptionInfo } from "@pixcap/ui-core/models/store/user.interface";

type AuthenticatedCallback = (subscription?: SubscriptionInfo) => void | undefined;

export interface IAuthService {
	setOnAuthenticated(onAuthenticated: AuthenticatedCallback);
	getOnAuthenticated(): AuthenticatedCallback;
}

export class AuthService implements IAuthService {
	private onAuthenticated: AuthenticatedCallback = undefined;

	setOnAuthenticated(onAuthenticated: AuthenticatedCallback) {
		this.onAuthenticated = onAuthenticated;
	}

	getOnAuthenticated(): AuthenticatedCallback {
		return this.onAuthenticated;
	}
}
