import type { Store } from 'vuex';
import { AppState } from '@pixcap/ui-core/models/store/app.interface';
import { BufferEncoders, RSocketClient, toBuffer } from 'rsocket-core';
import RSocketWebsocketClient from 'rsocket-websocket-client';
import { ReactiveSocket } from 'rsocket-types';
import { NAMESPACE as AUTH_NAMESPACE, ActionTypes as AuthActionTypes, IAuthState } from '@pixcap/ui-core/models/store/auth.interface';
import { MutationTypes as AppMutationTypes, NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';
import logger from '@pixcap/ui-core/helpers/logger';
import { _PIXCAP_ENV } from '@pixcap/ui-core/env';

export function delay(time) {
	return new Promise((resolve) => setTimeout(resolve, time));
}

export enum WEBSOCKET_OPERATIONS {
	SUBSCRIBE_CLONED_PROJECT = 'SUBSCRIBE_CLONED_PROJECT',
	SUBSCRIBE_STATE_JSON_READY = 'SUBSCRIBE_STATE_JSON_READY',
	SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE = 'SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE',
	SUBSCRIBE_USER_SESSIONS = 'SUBSCRIBE_USER_SESSIONS',
	SUBSCRIBE_CREDITS_CHANGE = 'SUBSCRIBE_CREDITS_CHANGE',
	SUBSCRIBE_REWARDS_CHANGE = 'SUBSCRIBE_REWARDS_CHANGE',
	SUBSCRIBE_PROJECT_USERS = 'SUBSCRIBE_PROJECT_USERS',
	SUBSCRIBE_PROJECT_COMMENTS = 'SUBSCRIBE_PROJECT_COMMENTS',
	SUBSCRIBE_SHARED_PROJECTS = 'SUBSCRIBE_SHARED_PROJECTS',
	SUBSCRIBE_FBX_EXPORTS = 'SUBSCRIBE_FBX_EXPORTS',
	SUBSCRIBE_RENDER_PROGRESS = 'SUBSCRIBE_RENDER_PROGRESS',
	SUBSCRIBE_RENDER_VARIANT_STATUS = 'SUBSCRIBE_RENDER_VARIANT_STATUS',
	SUBSCRIBE_NOTIFICATIONS_LIST = 'SUBSCRIBE_NOTIFICATIONS_LIST',
}

export enum ConnectionStatus {
	NOT_CONNECTED = 'NOT_CONNECTED',
	CONNECTING = 'CONNECTING',
	CONNECTED = 'CONNECTED',
	CLOSED = 'CLOSED',
	ERROR = 'ERROR',
}

export class RSocketCancelToken {
	private _rsocketCancelSrcArray: Function[] = [];

	source(rsocketCancelSrc: Function) {
		this._rsocketCancelSrcArray.push(rsocketCancelSrc);
	}

	cancel() {
		if (this._rsocketCancelSrcArray.length == 0) {
			logger.error('Cannot cancel rsocket request without setting rsocket cancel source');
			return;
		}
		for (const cancelSrc of this._rsocketCancelSrcArray) {
			cancelSrc();
		}
	}
}

export class WebSocketManager {
	private _client: RSocketClient<any, any>;
	private _rsocket: ReactiveSocket<any, any>;

	private _recoveryCallbacks: Function[] = [];
	private _isPerformingRecovery = false;

	private _isReconnected = true;
	private _isConnecting = false;
	private _connectingCallbacks: Function[] = [];

	private _store: Store<any>;

	static lastInitSocketManager: WebSocketManager;

	constructor(store: Store<any>) {
		WebSocketManager.lastInitSocketManager = this;
		this._store = store;
	}

	get isConnected() {
		return this._rsocket != null;
	}

	get isConnecting() {
		return this._isConnecting;
	}

	get origin() {
		return _PIXCAP_ENV.ROOT_URL.replace('https://', '');
	}

	get token() {
		const authState: IAuthState = this._store.state[`${AUTH_NAMESPACE}`];
		return authState.idToken;
	}

	async connect() {
		if (this.isConnected) return;
		return new Promise<void>((resolve, reject) => {
			if (this._isConnecting) {
				this._connectingCallbacks.push(resolve);
				return;
			}
			logger.log('start connecting');
			this._isConnecting = true;
			this._client = this._createClient();
			this._client.connect().subscribe({
				onComplete: (rsocket) => {
					this._rsocket = rsocket;
					this._store.commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_IS_WEBSOCKET_CONNECTED}`, true, { root: true });
					this._rsocket.connectionStatus().subscribe({
						onNext: (_status) => {
							logger.log(_status, 'connect onNext');
							if (_status.kind == ConnectionStatus.ERROR || _status.kind == ConnectionStatus.CLOSED) {
								if (this._rsocket) {
									this._store.commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_IS_WEBSOCKET_CONNECTED}`, false, { root: true });
									try {
										this._client.close();
										this._rsocket.close();
									} catch (err) {
										logger.warn(err, 'Encounter error closing reactive socket ');
									}
									this._rsocket = null;
									this._isReconnected = false;
								}
								const appState: AppState = this._store.state[APP_NAMESPACE];
								/// if tab isn't active so don't reconnect socket
								if (appState.isAppWindowActive) {
									logger.log('Performing new connection attempt from connection status error');
									setTimeout(this.connect.bind(this), 3000);
								} else {
									logger.log('App tab is hidden waiting for visibility change to reconnect');
								}
							} else if (_status.kind == ConnectionStatus.CONNECTED) {
								if (!this._isReconnected) this._performRecoveryCallbacks();
								this._store.commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_IS_WEBSOCKET_CONNECTED}`, true, { root: true });
							}
						},
						onError(error) {
							logger.error(error, 'Encounter connection status error');
						},
						onSubscribe: (sub) => sub.request(Number.MAX_SAFE_INTEGER),
					});
					resolve();
					this._performConnectingCallbacks();
					this._isConnecting = false;
					logger.log('end connecting');
				},
				onError: async (e) => {
					logger.warn(e, 'Encounter subscribe connect error');
					if (this._rsocket) {
						this._store.commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_IS_WEBSOCKET_CONNECTED}`, false, { root: true });
						try {
							this._client.close();
							this._rsocket.close();
						} catch (err) {
							logger.warn(err, 'Encounter error closing reactive socket on subscribe stage');
						}
						this._rsocket = null;
						this._isReconnected = false;
					}
					this._isConnecting = false;
					// to resolve the ongoing connection, when the last attempt get connected successfully
					this._connectingCallbacks.push(resolve);
					setTimeout(this.connect.bind(this), 3000);
				},
			});
		});
	}

	async subscribeRequestStream<D, T>(
		operation: WEBSOCKET_OPERATIONS,
		data: D,
		onReceiveCallback: (response: T) => any,
		recoveryCallback: Function,
		errorCallback?: Function,
		cancelToken?: RSocketCancelToken,
		isRetry = false
	): Promise<void> {
		if (!this.isConnected) await this.connect();

		if (recoveryCallback && !this._recoveryCallbacks.includes(recoveryCallback)) {
			this._recoveryCallbacks.push(recoveryCallback);
		}

		return new Promise((resolve, reject) => {
			const token = this.token;
			const payload = {
				data: toBuffer(JSON.stringify(data)),
				metadata: toBuffer(
					JSON.stringify({
						token,
						operation,
					})
				),
			};

			this._rsocket.requestStream(payload).subscribe({
				onNext: async (response) => {
					const responseData = JSON.parse(response.data.toString());

					logger.log(`---websocket message received--`);
					logger.log(operation);
					logger.log(responseData);
					logger.log(`-------------------------------`);

					if (responseData.status && responseData.status > 299) {
						const index = this._recoveryCallbacks.indexOf(recoveryCallback);
						if (index > -1) this._recoveryCallbacks.splice(index, 1);

						if (responseData.status == 401 && !isRetry) {
							await this._store.dispatch(`${AUTH_NAMESPACE}/${AuthActionTypes.REFRESH}`, undefined, { root: true });
							return resolve(this.subscribeRequestStream(operation, data, onReceiveCallback, recoveryCallback, errorCallback, cancelToken, true));
						} else {
							return reject(new Error(responseData.message));
						}
					}
					onReceiveCallback(responseData);
				},
				onComplete: () => {
					logger.log(`requestStream completed: ${operation}`);
					const index = this._recoveryCallbacks.indexOf(recoveryCallback);
					if (index > -1) this._recoveryCallbacks.splice(index, 1);
					resolve();
				},
				onError: (error) => {
					logger.log('RECEIVE ERROR from request stream itself (not disconnect error).');
					logger.warn(`subscribe Request Stream for ${operation}`, error);
					if (errorCallback) {
						errorCallback();
					}
					reject(error);
				},
				onSubscribe: ({ cancel, request }) => {
					if (cancelToken) cancelToken.source(cancel);
					// if we want to limit/signal acceptable number of requests, refactor for method to provide as param
					request(0x7fffffff);
				},
			});
		});
	}

	async subscribeRequestResponse<D, T>(operation: WEBSOCKET_OPERATIONS, data: D, recoveryCallback: Function, isRetry = false): Promise<T> {
		if (!this.isConnected) await this.connect();

		if (recoveryCallback && !this._recoveryCallbacks.includes(recoveryCallback)) {
			this._recoveryCallbacks.push(recoveryCallback);
		}

		return new Promise((resolve, reject) => {
			const token = this.token;
			const payload = {
				data: toBuffer(JSON.stringify(data)),
				metadata: toBuffer(
					JSON.stringify({
						token,
						operation,
					})
				),
			};
			this._rsocket.requestResponse(payload).subscribe({
				onComplete: async (response) => {
					const index = this._recoveryCallbacks.indexOf(recoveryCallback);
					if (index > -1) this._recoveryCallbacks.splice(index, 1);

					const responseData = JSON.parse(response.data.toString());

					if (responseData.status && responseData.status > 299) {
						if (responseData.status == 401 && !isRetry) {
							await this._store.dispatch(`${AUTH_NAMESPACE}/${AuthActionTypes.REFRESH}`, undefined, { root: true });
							return resolve(this.subscribeRequestResponse(operation, data, recoveryCallback, true));
						} else {
							return reject(new Error(responseData.message));
						}
					}
					resolve(responseData);
				},
				onError: (error) => {
					logger.log('RECEIVE ERROR from request itself (not disconnect error).');
					logger.warn(`subscribe Request response for ${operation}`, error);
					reject(error);
				},
			});
		});
	}
	private _performConnectingCallbacks() {
		logger.log('Perform connect callback for ', this._connectingCallbacks.length, ' connection');
		this._connectingCallbacks.forEach((resolve) => {
			resolve();
		});
		this._connectingCallbacks = [];
	}

	private _performRecoveryCallbacks() {
		if (this._isPerformingRecovery) return;
		this._isPerformingRecovery = true;
		this._isReconnected = true;
		logger.log('Recovering callbacks for ', this._recoveryCallbacks.length, ' callback');
		const recoveryCallbacks = this._recoveryCallbacks.slice();
		while (recoveryCallbacks.length > 0) {
			const callback = recoveryCallbacks.pop();
			const index = this._recoveryCallbacks.indexOf(callback);
			if (index > -1) this._recoveryCallbacks.splice(index, 1);
			callback();
		}
		this._isPerformingRecovery = false;
	}

	private _createClient() {
		//@ts-ignore
		const RSWebsocketClient = RSocketWebsocketClient.default || RSocketWebsocketClient;
		return new RSocketClient({
			setup: {
				dataMimeType: 'application/octet-stream',
				keepAlive: 3000,
				lifetime: 10000,
				metadataMimeType: 'text/plain',
			},
			transport: new RSWebsocketClient(
				{
					url: `wss://${this.origin}/api/v1/ws`,
				},
				BufferEncoders
			),
		});
	}
}
