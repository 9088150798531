import mixpanel from 'mixpanel-browser';
import debounce from 'lodash.debounce';
import { UTM_ORIGIN_SOURCE } from '@pixcap/ui-core/constants/shared.constants';

const debouncedEvents: { [eventName: string]: Function } = {};

const track = mixpanel.track;
const mixpanelTrack = async function (eventName, properties, optionsOrCallback, callback) {
	const thisTrack = track.bind(this);
	const utmOriginSource = localStorage.getItem(UTM_ORIGIN_SOURCE);
	thisTrack(eventName, { ...properties, 'Utm Origin Source': utmOriginSource }, optionsOrCallback, callback);
};

mixpanel.track = function (eventName, properties, optionsOrCallback, callback) {
	if (debouncedEvents[eventName] == null) {
		debouncedEvents[eventName] = debounce(mixpanelTrack.bind(this), 200, {
			leading: false,
			trailing: true,
		});
	}
	const debouncedTrack = debouncedEvents[eventName];

	debouncedTrack(eventName, properties, optionsOrCallback, callback);
};

export default mixpanel;
